
import http from "@/http";
import { Building, Key } from "@/interfaces";
import Vue, { PropType } from "vue";

import MyEditor from "@/components/key/MyEditor.vue";

export default Vue.extend({
  components: {
    MyEditor,
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    async save(e: Key) {
      const url = `buildings/${this.building.id}/keys`;

      await http.post(url, {
        ...e,
      });

      await this.$router.push(`/building/${this.building.id}/key`);
    },
  },
});
